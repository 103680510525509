import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { FiLogOut } from 'react-icons/fi';
import Loadable from 'react-loadable';
import config from '../../config.js';
import { validateToken } from '../api';
import { GUMROAD_PRODUCT_LINK, HAS_LOGIN_FEATURE, HEADER_CONFIG } from '../config/constants.js';
import Container from './Container';
import Link from './link';
import LoadingProvider from './mdxComponents/loading';
import Sidebar from './sidebar';

const help = require('./images/help.svg');

const isSearchEnabled = config.header.search && config.header.search.enabled ? true : false;

let searchIndices = [];

if (isSearchEnabled && config.header.search.indexName) {
  searchIndices.push({
    name: `${config.header.search.indexName}`,
    title: `Results`,
    hitComp: `PageHit`,
  });
}

const LoadableComponent = Loadable({
  loader: () => import('./search/index'),
  loading: LoadingProvider,
});

function myFunction() {
  var x = document.getElementById('navbar');

  if (x.className === 'topnav') {
    x.className += ' responsive';
  } else {
    x.className = 'topnav';
  }
}

const StyledBgDiv = styled('div')`
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f8;
  position: relative;
  display: none;
  background: ${(props) => (props.isDarkThemeActive ? '#001932' : undefined)};

  @media (max-width: 767px) {
    display: block;
  }
`;

const Header = ({ location, isDarkThemeActive, toggleActiveTheme }) => (
  <StaticQuery
    query={graphql`
      query headerTitleQuery {
        site {
          siteMetadata {
            headerTitle
            githubUrl
            helpUrl
            tweetText
            logo {
              link
              image
            }
            headerLinks {
              link
              text
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        site: {
          siteMetadata: { headerTitle, githubUrl, helpUrl, tweetText, logo, headerLinks },
        },
      } = data;

      const isLoggedInUser = validateToken();

      return (
        <div className={'navBarWrapper'}>
          <HeaderUI
            headerTitle={headerTitle}
            headerLinks={headerLinks}
            isLoggedInUser={isLoggedInUser}
          />

          <StyledBgDiv isDarkThemeActive={isDarkThemeActive}>
            <div className={'navBarDefault removePadd'}>
              <span
                onClick={myFunction}
                className={'navBarToggle'}
                onKeyDown={myFunction}
                role="button"
                tabIndex={0}
              >
                <span className={'iconBar'}></span>
                <span className={'iconBar'}></span>
                <span className={'iconBar'}></span>
              </span>
            </div>
            {isSearchEnabled ? (
              <div className={'searchWrapper'}>
                <LoadableComponent collapse={true} indices={searchIndices} />
              </div>
            ) : null}
          </StyledBgDiv>
        </div>
      );
    }}
  />
);

const HeaderUI = (props) => {
  const [showLeadForm, setShowLeadForm] = useState(false);

  useEffect(() => {
    const showLeadPopup = localStorage.getItem('showLeadPopup');
    console.log(showLeadPopup, 'showLeadPopup')
    if (!props.isLoggedInUser) {
      if (showLeadPopup !== false) {
        setTimeout(() => {
          setShowLeadForm(true);
        }, 10000);
      }
    }
  }, []);

  const closeCallback = () => {
    setShowLeadForm(false);
  };

  const handleLoginClick = () => {
    if (window?.gtag) {
      gtag('event', 'Login Button Click', {});
    }
  };

  const handleCTAClick = () => {
    console.log(' CTA Click');
    setShowLeadForm(true);

    setTimeout(() => {
      window.open('https://dipakkr.gumroad.com/l/api-for-pm', '_blank');
    }, 500);
  };

  const location = {};
  const linkRef = useRef(null);

  const handleRedirect = () => {
    window.open('https://dipakkr.gumroad.com/l/api-for-pm', '_blank');
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <nav className={'navBarDefault'}>
        <div className={'navBarHeader'}>
          <Link to="/">
            {' '}
            <H1>{props.headerTitle}</H1>{' '}
          </Link>
        </div>

        <a ref={linkRef} id="gumroadLink" href={GUMROAD_PRODUCT_LINK}>
          Gumroad
        </a>

        {isSearchEnabled ? (
          <div className={'searchWrapper hiddenMobile navBarUL'}>
            <LoadableComponent collapse={true} indices={searchIndices} />
          </div>
        ) : null}

        <div id="navbar" className={'topnav'}>
          <div className={'visibleMobile'}>
            <Sidebar location={location} color={'#e2e2e2'} />
            <hr />
          </div>
          <ul className={'navBarUL navBarNav navBarULRight'}>
            {props.headerLinks.map((link, key) => {
              if (link.link !== '' && link.text !== '') {
                return (
                  <li key={key}>
                    <a
                      className="sidebarLink"
                      href={link.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: link.text }}
                    />
                  </li>
                );
              }
            })}

            <li>
              <a href={'https://learn.pminterview.in?utm_source=apiheader'} target="_blank">
                PM Interview Questions
              </a>
            </li>

            {!props.isLoggedInUser && HAS_LOGIN_FEATURE ? (
              <li>
                <a onClick={handleLoginClick} href="/login">
                  Login
                </a>

                <div className="btn">
                  <button
                    className="form-control btn btn-danger"
                    type="submit"
                    onClick={handleCTAClick}
                  >
                    Buy Now
                  </button>
                </div>
              </li>
            ) : (
              HAS_LOGIN_FEATURE && (
                <li onClick={handleLogout}>
                  <Text>
                    <FiLogOut color="white" /> Logout{' '}
                  </Text>
                </li>
              )
            )}

            {HEADER_CONFIG.CONTACT_INFO.isEnabled && (
              <li>
                <a onClick={handleLoginClick} href={HEADER_CONFIG.CONTACT_INFO.URL}>
                  Contact Us
                </a>
              </li>
            )}
          </ul>
        </div>
      </nav>

      <Container closeCallback={closeCallback} isShown={showLeadForm} clickRef={handleRedirect} />
    </>
  );
};

export default Header;

const Button = styled.button`
  max-width: 100%;
  padding: 11px 13px;
  color: rgb(253, 249, 243);
  font-weight: 600;
  text-transform: uppercase;
  background: rgb(200, 50, 70);
  border: none;
  border-radius: 3px;
  outline: 0;
  cursor: pointer;
  margin-top: 0.6rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
`;

const H1 = styled.h1`
  color: white;
  font-size: 1.5em;
`;

const Text = styled.p`
  color: white;
  font-size: 1em;
  margin: 0 auto;
  cursor: pointer;
`;
