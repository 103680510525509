import React from 'react';
import styled from 'styled-components';
import { CONTACT_US_CONFIG, FOOTER_CONFIG } from '../config/constants';

const Footer = (props) => {
  return (
    <div className={'navBarWrapper'}>
      <nav className={`footerDefault ${props?.position} ? ${props.position} : `}>
        <div className={'navBarHeader'}>
          <Text> © 2022 | learn.apiforpm.tech | All rights reserved. </Text>
        </div>
        <div className={'navBarHeader'}>
          {}
          <a href="https://bit.ly/3EVi3QA" target="_blank">
            <LinkText> Want to host your own course ? </LinkText>
          </a>
          <a href={FOOTER_CONFIG.CONTACT_LINK}>
            <LinkText>Contact Us</LinkText>
          </a>
        </div>
      </nav>
    </div>
  );
};

export default Footer;

const Text = styled.p`
  font-weight: normal;
  text-align: center;
  padding: 1rem;
  color: #b2b2b2;
`;

const LinkText = styled.p`
  font-weight: normal;
  text-align: center;
  padding: 1rem;
  color: #b2b2b2;
  cursor: pointer;
`;
