import { Layout } from '$components';
import { graphql, navigate } from 'gatsby';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import config from '../../config';
import { validateToken } from '../api';
import NextPrevious from '../components/NextPrevious';
import { StyledHeading, StyledMainWrapper } from '../components/styles/Docs';
import { GUMROAD_PRODUCT_LINK, UNLOCKED_CHAPTERS } from '../config/constants';

const forcedNavOrder = config.sidebar.forcedNavOrder;

const MDXRuntimeTest = (props) => {
  const { data } = props;

  if (!data) {
    return props.children;
  }

  const { allMdx, mdx, site } = data;

  useEffect(() => {
    if (props.path === '/') {
      navigate('/module-1');
    }

    if (props?.location?.search === '?source=landing') {
      localStorage.setItem('showLeadPopup', false);
    }

    const validate = async () => {
      if (!UNLOCKED_CHAPTERS.includes(mdx.fields.slug)) {
        const isValidToken = validateToken();

        if (!isValidToken) {
          window.location.href = '/register';
        }
      }
    };
    validate();
  }, [mdx.fields.slug]);

  const navItems = allMdx.edges
    .map(({ node }) => node.fields.slug)
    .filter((slug) => slug !== '/')
    .sort()
    .reduce(
      (acc, cur) => {
        if (forcedNavOrder.find((url) => url === cur)) {
          return { ...acc, [cur]: [cur] };
        }

        let prefix = cur.split('/')[1];

        if (config.gatsby && config.gatsby.trailingSlash) {
          prefix = prefix + '/';
        }

        if (prefix && forcedNavOrder.find((url) => url === `/${prefix}`)) {
          return { ...acc, [`/${prefix}`]: [...acc[`/${prefix}`], cur] };
        } else {
          return { ...acc, items: [...acc.items, cur] };
        }
      },
      { items: [] }
    );

  const nav = forcedNavOrder
    .reduce((acc, cur) => {
      return acc.concat(navItems[cur]);
    }, [])
    .concat(navItems.items)
    .map((slug) => {
      if (slug) {
        const { node } = allMdx.edges.find(({ node }) => node.fields.slug === slug);

        return { title: node.fields.title, url: node.fields.slug };
      }
    });

  // meta tags
  const metaTitle = mdx.frontmatter.metaTitle;

  const metaDescription = mdx.frontmatter.metaDescription;

  let canonicalUrl = config.gatsby.siteUrl;

  canonicalUrl =
    config.gatsby.pathPrefix !== '/' ? canonicalUrl + config.gatsby.pathPrefix : canonicalUrl;
  canonicalUrl = canonicalUrl + mdx.fields.slug;

  return (
    <Layout {...props}>
      <Helmet>
        {metaTitle ? <title>{metaTitle}</title> : null}
        {metaTitle ? <meta name="title" content={metaTitle} /> : null}
        {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        {metaTitle ? <meta property="og:title" content={metaTitle} /> : null}
        {metaDescription ? <meta property="og:description" content={metaDescription} /> : null}
        {metaTitle ? <meta property="twitter:title" content={metaTitle} /> : null}
        {metaDescription ? <meta property="twitter:description" content={metaDescription} /> : null}
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <div className={'titleWrapper'}>
        <StyledHeading>{mdx.fields.title}</StyledHeading>
      </div>

      <StyledMainWrapper>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </StyledMainWrapper>
      <div className={'addPaddTopBottom'}>
        <NextPrevious mdx={mdx} nav={nav} />
      </div>
    </Layout>
  );
};

export default MDXRuntimeTest;

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        docsLocation
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        id
        title
        slug
      }
      body
      tableOfContents
      parent {
        ... on File {
          relativePath
        }
      }
      frontmatter {
        metaTitle
        metaDescription
      }
    }
    allMdx {
      edges {
        node {
          fields {
            slug
            title
          }
        }
      }
    }
  }
`;
