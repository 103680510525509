import axios from 'axios';
import { GUMROAD_PRODUCT_KEY, LS_KEYS, TEST_MODE, UNLOCKED_CHAPTERS } from '../config/constants';
var qs = require('qs');

export const validateLicenseKey = async (licenseKey) => {
  try {
    var data = qs.stringify({
      product_permalink: GUMROAD_PRODUCT_KEY,
      license_key: licenseKey,
    });

    var config = {
      url: 'https://api.gumroad.com/v2/licenses/verify',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await axios.post('https://api.gumroad.com/v2/licenses/verify', data, config);
    return response?.data;
  } catch (err) {}
};

export const validateToken = () => {
 
  const productData =
    typeof window !== 'undefined' && window.localStorage.getItem(LS_KEYS.LICENSE_KEY);

  if (!productData) {
    return false;
  }

  const emailData =
    typeof window !== 'undefined' && window.localStorage.getItem(LS_KEYS.USER_EMAIL);
  if (!emailData) {
    return false;
  }

  return true;
};

export const checkItemLockStatus = (url) => {
  // free chapters
  if (UNLOCKED_CHAPTERS.includes(url)) {
    return false;
  }

  // is loggedIn
  const isLoggedIn = validateToken();

  if (isLoggedIn) {
    return false;
  }

  return true;
};

export const submitLeadData = async (payload) => {
  const scriptURL =
    'https://script.google.com/macros/s/AKfycbx5BwaPgBvb6xYn4meqWhMqkR53jYESSfKyrHB51168_LKoBcvNcdqoY48Gv9hs5qbH-A/exec';

  let formData = new FormData();
  formData.append('email', payload.email);
  formData.append('url', payload.url);
  formData.append('hostName', payload.host);



  const response = await fetch(scriptURL, { method: 'POST', body: formData });

  return response;

  //  .then(response => call)
  // .catch(error => console.error('Error!', error.message))
};
