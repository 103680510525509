import React, { Component } from 'react';
import { submitLeadData } from '../../api';
import { Modal } from '../../components/modal';
export class Container extends Component {
  state = { isShown: false, isLoading : false };

  componentDidUpdate(){
      if(this.props.isShown !== this.state.isShown){
          this.setState({isShown : this.props.isShown})
      }
  }

  showModal = () => {
    this.setState({ isShown: true }, () => {
      this.closeButton.focus();
    });
    this.toggleScrollLock();
  };
  closeModal = () => {
    this.setState({ isShown: false });
    this.props.closeCallback()
  };
  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  };
  onClickOutside = (event) => {
    if (this.modal && this.modal.contains(event.target)) return;
    this.closeModal();
  };

  toggleScrollLock = () => {
    document.querySelector('html').classList.toggle('scroll-lock');
  };


   handleLeadSubmit = async (e) => {
    event.preventDefault(e);
    const emailData = e.target.email.value;
    if(!emailData){
      alert('Please enter a valid email')
      return;
    }
    this.setState({isLoading : true})


 const currentUrl = window.location.href;
 const hostName = window.location.hostname

 submitLeadData({email : emailData, url : currentUrl, host : hostName});

    this.setState({isLoading : false});    
    this.closeModal();
    this.props.clickRef();

    if(data.ok && data.status == 200){
      window?.gtag && gtag('event', 'Lead Submit - Success', {});
    }

   
  };


  render() {
    return (
      <React.Fragment>        
        {this.state.isShown ? (
          <Modal
            onSubmit={this.handleLeadSubmit}
            modalRef={(n) => (this.modal = n)}
            buttonRef={(n) => (this.closeButton = n)}
            closeModal={this.closeModal}
            onKeyDown={this.onKeyDown}
            onClickOutside={this.onClickOutside}
            isLoading={this.state.isLoading}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Container;
