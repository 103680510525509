import styled from '@emotion/styled';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { ExternalLink } from 'react-feather';
import config from '../../../config';
import { validateToken } from '../../api';
import { FEEDBACK_FORM_CONFIG, PRODUCT_HUNT_CONFIG } from '../../config/constants';
import Tree from './tree';

// eslint-disable-next-line no-unused-vars
const ListItem = styled(({ className, active, level, ...props }) => {
  return (
    <li className={className}>
      <a href={props.to} {...props} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    </li>
  );
})`
  list-style: none;

  a {
    color: #5c6975;
    text-decoration: none;
    font-weight: ${({ level }) => (level === 0 ? 700 : 400)};
    padding: 0.45rem 0 0.45rem ${(props) => 2 + (props.level || 0) * 1}rem;
    display: block;
    position: relative;

    &:hover {
      color: #5281db !important;
    }

    ${(props) =>
      props.active &&
      `
      // color: #663399;
      border-color: rgb(230,236,241) !important;
      border-style: solid none solid solid;
      border-width: 1px 0px 1px 1px;
      background-color: #fff;
    `} // external link icon
    svg {
      float: right;
      margin-right: 1rem;
    }
  }
`;

const Sidebar = styled('aside')`
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: fixed;
  padding-left: 0px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: sticky;
  top: 0;
  padding-right: 0;
  -webkit-box-shadow: -1px 0px 15px 1px rgba(175, 158, 232, 0.4);

  @media only screen and (max-width: 1023px) {
    width: 100%;
    /* position: relative; */
    height: 100vh;
  }

  @media (min-width: 767px) and (max-width: 1023px) {
    padding-left: 0;
  }

  @media only screen and (max-width: 767px) {
    padding-left: 0px;
    height: auto;
  }
`;

const Divider = styled((props) => (
  <li {...props}>
    <hr />
  </li>
))`
  list-style: none;
  padding: 0.5rem 0;

  hr {
    margin: 0;
    padding: 0;
    border: 0;
    border-bottom: 1px solid #ede7f3;
  }
`;

const SidebarLayout = ({ location, color }) => {
  const isLoggedInUser = validateToken();

  const handleUnlockClick = () => {
    window?.gtag && gtag('event', 'Feedback Form - Click', {
      'event_category': 'Feedback Form',
      'event_label': 'Feedback Form CTA ',
    });
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx {
            edges {
              node {
                fields {
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={({ allMdx }) => {
        return (
          <Sidebar>
            {config.sidebar.title ? (
              <div
                className={'sidebarTitle hiddenMobile'}
                dangerouslySetInnerHTML={{ __html: config.sidebar.title }}
              />
            ) : null}
            <ul className={'sideBarUL'}>
              <Tree edges={allMdx.edges} color={color} />

              {config.sidebar.links.map((link, key) => {
                if (link.link !== '' && link.text !== '') {
                  return (
                    <ListItem key={key} to={link.link}>
                      {link.text}
                      <ExternalLink size={14} />
                    </ListItem>
                  );
                }
              })}

              <div id="gumroad-unlock-btn">
                <Divider />

                {isLoggedInUser  && FEEDBACK_FORM_CONFIG.isEnabled && (
                  <>
                    <ListItem key={'key'} to={FEEDBACK_FORM_CONFIG.URL}>
                      <a onClick={handleUnlockClick} class="btn btn-info">
                        Give Feedback ✍️
                      </a>
                    </ListItem>
                  </>
                )}

                {!isLoggedInUser && PRODUCT_HUNT_CONFIG.isEnabled && (
                 PRODUCT_HUNT_CONFIG.FEATURE_LINK
                )}
              </div>
            </ul>
          </Sidebar>
        );
      }}
    />
  );
};

export default SidebarLayout;
